<template>
    <div>
      <SSRAgTable
      v-on="$listeners"
      :frameworkComponents="frameworkComponents"
        :columns="columnDefs"
        :data="rowData"
        :totalRows="totalRows"
        :totalPages="totalPages"
        @fetchData="getGridData"
        @updateSelectedItems="onSelectedItems"
      >
        <template slot="extraHeaderActions">
          <vs-button
            class="mb-4 md:mb-0 mr-4"
            v-if="this.countSelected"
            @click="openPopupChangeState('charged')"
            v-can="'change_panel_charged_campaign'"
          >
            Cambiar estado cobrado ({{ this.countSelected }})
          </vs-button>
        </template>
      </SSRAgTable>
  
      <!-- Popup change state -->
      <vs-popup :title="popupActiveChangeStateTitle" :active.sync="popupActiveChangeState">
        <vs-table noDataText="Error" :data="selectedRows">
          <template slot="thead">
            <vs-th>ID</vs-th>
            <vs-th>Nombre</vs-th>
            <vs-th>Estado actual</vs-th>
            <vs-th>Nuevo estado</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
                <vs-chip color="success" v-if="data[indextr].charged">COBRADO</vs-chip>
                <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
              </vs-td>
              <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
                <vs-chip color="success" v-if="!data[indextr].charged">COBRADO</vs-chip>
                <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
              </vs-td>
  
            </vs-tr>
          </template>
        </vs-table>
        <vs-button @click="changeState()" color="primary" type="filled" class="float-right mt-2">Cambiar</vs-button>
      </vs-popup>
      <!-- Popup Documentation  -->
      <!-- Documentation popup -->
    <show-docs-popup
      :poModalActive="poModalActive"
      :poModalData="poModalData"
    />
  
    </div>
  </template>
    
    <style>
    /* Add !important to display: none added by v-show directive */
    *[style*="display: none"] {
      display: none !important;
    }
    </style>
    
    <script>
    import { LicenseManager } from 'ag-grid-enterprise'
    LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
    import SSRAgTable from '../components/ag-table/SSRAgTable.vue'
    import appConfig from '@/../appConfig.js'
    import ChargedRenderer from './CampaignsChargedRenderer.vue'

  import ShowDocsPopup from '../components/popups/ShowDocsPopup.vue'
    
  import ProfitPercentageTooltip from './ProfitPercentageTooltip.vue'
  import { columnDefs } from '../utils/tables/charges.screen'
    
    export default {
      name: 'charges-table',
      components: {
        SSRAgTable,
        ShowDocsPopup
      },
      props: {
        filters: {
          type: Object,
          required: true
        },
        filterType: {
          type: Object,
          required: true
        }
      },
      data () {
        return {
          // Charges grid options
          currentPage: 1,
          totalRows: 0,
          totalPages: 1,
          columnDefs: columnDefs({
            openPoModal: this.openPoModal.bind(this) 
          }),
          rowData: [],
          frameworkComponents: null,
          //Items selected
          selectedRows: null,
          countSelected: 0,
          //loading flag
          isLoading: false,
          //Documentation
          poModalActive: false,
          poModalData: null,
          //change state
          popupActiveChangeState: false,
          popupActiveChangeStateTitle: 'Cambiar estado',
          popupActiveChangeStateType: '',
        }
      },
      watch: {},
      computed: {},
      methods: {
        getGridData ({ page = 1, pageSize = 20 }) {
          this.countSelected = 0
          this.$http.get(`${appConfig.apiUrl}/panel/campaigns`, {
            params: {
              filters: this.filters,
              page: page,
              page_size: pageSize
            }
          })
          .then((response) => {
            const {total, data, last_page, current_page} = response.data
  
            this.currentPage = current_page
            this.totalRows = total
            this.rowData = data
            this.totalPages = last_page
          })
          .catch((error) => {
            console.log(error)
          })
          // .then(() => {
          //   this.isLoading = false
          // })
        },
        // Method to open the purchase order modal
        openPoModal (data) {
          this.poModalActive = true
          this.poModalData = JSON.parse(data)
        },
        // Method to change the state of the selected campaigns
        openPopupChangeState (type) {
          this.popupActiveChangeStateType = type
          switch (type) {
          case 'charged':
            this.popupActiveChangeStateTitle = 'Cambiar estado cobrado'
            break
          default:
            break
          }
          this.popupActiveChangeState = !this.popupActiveChangeState
        },
        changeState () {
          const campaignsId = []
          this.selectedRows.forEach((row) => {
            campaignsId.push(row.id)
          })
          this.apiCall(campaignsId)
        },
        apiCall (campaignsId) {
          this.$http.put(`${appConfig.apiUrl}/panel/campaigns/states`, {
            campaigns: campaignsId,
            type: this.popupActiveChangeStateType
          })
            .then((response) => {
              this.$vs.notify({
                title: response.data.message,
                text: ':)',
                color: 'primary'
              })
              // Reload table
              this.getGridData({page: this.currentPage})
            })
            .catch((error) => {
              console.log(error)
              this.$vs.notify({
                title: 'Error',
                text: error.message,
                color: 'danger'
              })
            })
            .then(() => {
              // always executed
              this.popupActiveChangeState = !this.popupActiveChangeState
            })
          },
          onSelectedItems (selectedItems) {
            this.selectedRows = selectedItems
            this.countSelected = selectedItems.length
          }
        },
        beforeMount () {
        this.frameworkComponents = {
          chargedRenderer: ChargedRenderer,
          profitPercentageTooltip: ProfitPercentageTooltip
        }
      },
      mounted () {}
    }
    </script>
    